import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'topup',
    pathMatch: 'full'
  },
  
  {
    path: 'topup',
    loadChildren: './pages/topup/topup.module#TopupPageModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },

  {
    path: 'topup',
    loadChildren: () => import('./pages/topup/topup.module').then( m => m.TopupPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'topuppayment',
    loadChildren: () => import('./pages/topuppayment/topuppayment.module').then( m => m.TopuppaymentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'topupsuccessful',
    loadChildren: () => import('./pages/topupsuccessful/topupsuccessful.module').then( m => m.TopupsuccessfulPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'balancetransfer',
    loadChildren: () => import('./pages/balancetransfer/balancetransfer.module').then( m => m.BalancetransferPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transactionhistory',
    loadChildren: () => import('./pages/transactionhistory/transactionhistory.module').then( m => m.TransactionhistoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'updateaccount',
    loadChildren: () => import('./pages/updateaccount/updateaccount.module').then( m => m.UpdateaccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'activate',
    loadChildren: () => import('./pages/activate/activate.module').then( m => m.ActivatePageModule)
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
