import { Storage } from '@ionic/storage';
import { Component, OnInit } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Subscription } from 'rxjs';
import { CommonfunctionService } from 'src/app/services/commonfunction.service';
import { Router } from '@angular/router';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Top Up',
      url: '/topup',
      icon: 'home'
    },
    {
      title: 'Balance Transfer',
      url: '/balancetransfer',
      icon: 'wallet'
    },
    {
      title: 'My Transactions',
      url: '/transactionhistory',
      icon: 'reorder-four'
    },
    {
      title: 'My Account',
      url: '/updateaccount',
      icon: 'person-circle'
    }
  ];
  
  currentUserDisplayName: any;
  private subsCription: Subscription;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private CFS: CommonfunctionService,
    private router: Router,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private storage: Storage,
    private iab: InAppBrowser
  ) {
    this.initializeApp();

    // subscribe to home component messages
    this.subsCription = this.CFS.getDisplayName().subscribe( displayName => {
      if (displayName) {
        this.currentUserDisplayName = displayName;
        this.currentUserDisplayName = this.currentUserDisplayName.display_name;
        //console.log('Current Display Name: ', this.currentUserDisplayName);
      } else {
        // clear messages when empty message received
        this.currentUserDisplayName = '';
      }
    });

    if(localStorage.getItem('display_name')){
      this.currentUserDisplayName = {
        'display_name': localStorage.getItem('display_name')
      }
    }

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is('cordova')) {
        this.setupPush();
      }
    });
  }

  setupPush() {
    // "YOUR_APPID", "YOUR_GOOGLE_PROJECT_NUMBER_IF_ANDROID"
    this.oneSignal.startInit('f5f9d4cc-3451-4244-ae15-e03e5457b428', '690961019645');
 
    // prompt for permission
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let msg = data.notification.payload.body;
      let title = data.notification.payload.title;
      let additionalData = data.notification.payload.additionalData;
 
      this.showAlert(title, msg, additionalData.task,);
    });
    this.oneSignal.endInit();
  }

  async showAlert(title, msg, task, ) {

      const alert = await this.alertCtrl.create({
        header: title,
        subHeader: msg,
        buttons: [
          {
            text: `Action: ${task}`,
            handler: () => {
              //
            }
          }
        ]
      });
      alert.present();
  }

  logOut(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
    window.location.reload();
  }

  moreInfo(){
    this.iab.create('https://cimargaritafestival.com/','_system');
    //console.log('App Support');
  }


  ngOnInit() {

  }
}
